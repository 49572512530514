<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="pt-0">
                    <b-card-body class="pt-0">
                        <!-- Tabs upper static contents -->
                        <b-row>
                            <b-col
                                cols="12"
                                md="2"
                                class="mb-md-0 mb-2"
                            >
                                <label>Season</label>
                                <b-form-select
                                    size="sm"
                                    v-model="season_id"
                                    @change="fetchQuestionnaires()"
                                >
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option
                                        v-for="season in seasons"
                                        :key="season.id"
                                        :value="season.id"
                                    >
                                        {{ season.startYear }}/{{ season.endYear }}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-col>

                            <b-col
                                cols="12"
                                md="2"
                                class="mb-md-0 mb-2"
                            >
                                <label>Visit Cycle</label>
                                <b-form-select
                                    size="sm"
                                    v-model="selectedQuestionnaire"
                                    @change="changeCycle()"
                                >
                                    <b-form-select-option
                                        v-for="(questionnaire, index) in questionnaires"
                                        :key="questionnaire.id"
                                        :value="questionnaire"
                                        v-if="questionnaire.status === 1 && !reachedEndDate(questionnaire.endDate)"
                                        :selected="index === 0"
                                    >
                                        {{ questionnaire.cycle }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger" v-if="serverErrors && serverErrors.questionnaire_id">{{ serverErrors.questionnaire_id[0] }}</small>
                            </b-col>
                            <!-- {{ question }} -->
                        </b-row>
                        <!-- ./ Tabs upper static contents -->
                        <hr />

                        <b-row>
                            <b-col cols="12">
                                <!--- Start Tabs -->
                                <b-tabs
                                    vertical
                                    pills
                                    content-class="col-12 col-md-9 mt-1 mt-md-0"
                                    nav-wrapper-class="col-md-3 col-12"
                                    nav-class="nav-left"
                                    v-if="selectedQuestionnaire"
                                >
                                <h5 class="mb-0">
                                    Question <small>Response collecting tool:</small> <small class="text-danger" v-if="serverErrors && (serverErrors.type_id || serverErrors.tool)">{{ serverErrors.type_id[0] || serverErrors.tool[0] }}</small>
                                </h5>

                                <b-row>
                                    <b-col cols="6" md="2" v-for="questionType in questionsTypes" :key="questionType.id">
                                        <b-form-radio
                                            plain
                                            name="type"
                                            :value="questionType"
                                            v-model="type"
                                            @change="changeTool()"
                                        >
                                            {{ questionType.description }}
                                        </b-form-radio>
                                    </b-col>
                                </b-row>

                                    <!-- general tab -->
                                    <b-tab
                                        active
                                        v-if="selectedQuestionnaire.sections.length === 0"
                                    >

                                        <!-- title -->
                                        <template #title>
                                            <feather-icon
                                            icon="DiscIcon"
                                            size="18"
                                            class="mr-50"
                                            />
                                            <span class="font-weight-bold">None Sectioned</span>
                                        </template>

                                        <!-- <account-setting-general
                                            v-if="options.general"
                                            :general-data="options.general"
                                        /> -->
                                        <b-row>
                                            <b-col cols="12">
                                                <b-row>
                                                    <b-col cols="12" md="5">
                                                        <b-row>
                                                            <b-col cols="12">
                                                                <label for="textarea-default">Question</label>
                                                                <b-form-textarea
                                                                    id="textarea-default"
                                                                    placeholder="Type your question here"
                                                                    rows="2"
                                                                    v-model="question.description"
                                                                />
                                                                <small class="text-danger" v-if="serverErrors && serverErrors.description">{{ serverErrors.description[0] }}</small>
                                                            </b-col>
                                                        </b-row>

                                                        <b-row>
                                                            <b-col cols="8">
                                                                <label>Triggered by</label>
                                                                <b-form-select
                                                                    size="sm"
                                                                    v-model="question.question_id"
                                                                    @change="repopulateOptions"
                                                                >
                                                                    <b-form-select-option :value="null" selected>Select...</b-form-select-option>
                                                                    <b-form-select-option
                                                                        v-for="(question, index) in triggers"
                                                                        :key="index"
                                                                        :value="question.id"
                                                                        :v-if="question.options.length > 0"
                                                                    >
                                                                        {{ question.description }}
                                                                    </b-form-select-option>
                                                                </b-form-select>
                                                            </b-col>
                                                            <b-col cols="4">
                                                                <label>After Opting</label>
                                                                <b-form-select
                                                                    size="sm"
                                                                    v-model="question.option_id"
                                                                >
                                                                    <b-form-select-option :value="null" selected>Select...</b-form-select-option>
                                                                    <b-form-select-option
                                                                        v-for="option in options"
                                                                        :key="option.id"
                                                                        :value="option.id"
                                                                        selected
                                                                    >
                                                                        {{ option.value }}
                                                                    </b-form-select-option>
                                                                </b-form-select>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>

                                                    <b-col cols="12" md="7" v-if="type.tool !== 'textarea' && type.tool !== 'textbox' && type.tool !== 'numberbox'">
                                                        <!-- True/False Question -->
                                                        <b-row v-if="type.tool === 'binary'">
                                                            <b-col cols="12" v-if="serverErrors && serverErrors.labels">
                                                                <small class="text-danger">{{ serverErrors.labels[0] }}</small>
                                                            </b-col>
                                                            <b-col cols="12" md="3" class="pt-1">
                                                                <label for="trueLabel">True Label</label>
                                                                <b-form-input
                                                                    id="trueLabel"
                                                                    name="trueLabel"
                                                                    v-model="question.labels.trueLabel"
                                                                    size="sm"
                                                                    placeholder="e.g. Yes"
                                                                />
                                                                <small class="text-danger" v-if="serverErrors && serverErrors.labels.trueLabel">{{ serverErrors.labels.trueLabel[0] }}</small>
                                                            </b-col>
                                                            <b-col cols="12" md="3" class="pt-1">
                                                                <label for="falseLabel">False Label</label>
                                                                <b-form-input
                                                                    id="falseLabel"
                                                                    name="falseLabel"
                                                                    v-model="question.labels.falseLabel"
                                                                    size="sm"
                                                                    placeholder="e.g. No"
                                                                />
                                                                <small class="text-danger" v-if="serverErrors && serverErrors.labels.falseLabel">{{ serverErrors.labels.falseLabel[0] }}</small>
                                                            </b-col>
                                                        </b-row>
                                                        <!-- ./True/False Question -->

                                                        <!-- Rating Question -->
                                                        <b-row v-if="type.tool === 'rate'">
                                                            <b-col cols="4" class="pt-1">
                                                                <label for="minimum" :class="(serverErrors && serverErrors.minimum)? 'text-danger' : 'text-default'">Scale of</label>
                                                                <b-form-input
                                                                    id="minimum"
                                                                    name="minimum"
                                                                    v-model="question.minimum"
                                                                    size="sm"
                                                                    type="number"
                                                                />
                                                            </b-col>
                                                            <b-col cols="4" class="pt-1">
                                                                <label for="maximum" :class="(serverErrors && serverErrors.maximum)? 'text-danger' : 'text-default'">To</label>
                                                                <b-form-input
                                                                    id="maximum"
                                                                    name="maximum"
                                                                    v-model="question.maximum"
                                                                    size="sm"
                                                                    type="number"
                                                                />
                                                            </b-col>
                                                            <b-col cols="4" class="pt-1">
                                                                <b-form-group>
                                                                    <label for="display" :class="(serverErrors && serverErrors.display)? 'text-danger' : 'text-default'">Display System</label>
                                                                    <b-form-radio-group
                                                                        plain
                                                                        :options="[{ text: 'Stars', value: 'stars' }, { text: 'Number box', value: 'box' }]"
                                                                        id="display"
                                                                        name="display"
                                                                        v-model="question.display"
                                                                        stacked
                                                                    />
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <!-- ./Rating Question -->

                                                        <!-- Multiple Choice Question -->
                                                        <b-row v-if="type.tool === 'multiple'">
                                                            <b-col cols="12">
                                                                <b-row>
                                                                    <b-col cols="6">
                                                                        <small>Choices</small> 
                                                                        <small class="text-danger" v-if="serverErrors && serverErrors.choices">({{ serverErrors.choices[0] }})</small>
                                                                    </b-col>
                                                                    <b-col cols="6" class="text-right">
                                                                        <b-button
                                                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                                            variant="flat-success"
                                                                            class="btn-icon"
                                                                            size="sm"
                                                                            title="Add row"
                                                                            @click="addRow()"
                                                                        >
                                                                            <feather-icon icon="PlusSquareIcon" />
                                                                        </b-button>
                                                                    </b-col>
                                                                </b-row>
                                                                <div
                                                                    style="border: 1px solid #eee;height:12vh;overflow-y:scroll;overflow-x: hidden;padding: 8px 8px;"
                                                                >
                                                                    <table class="table table-sm table-condensed" width="100%">
                                                                        <tbody>
                                                                            <tr v-for="(choice, index) in question.choices" :key="index">
                                                                                <td  width="5%">
                                                                                    {{ index + 1 }}
                                                                                </td>
                                                                                <td>
                                                                                    <b-form-input
                                                                                        size="sm"
                                                                                        name="description"
                                                                                        v-model="question.choices[index].description"
                                                                                        row="1"
                                                                                    />
                                                                                </td>
                                                                                <td width="5%">
                                                                                    <span
                                                                                        title="Remove this choice"
                                                                                        style="cursor:pointer"
                                                                                        @click="removeRow(index)"
                                                                                    >
                                                                                        <feather-icon class="ml-4 pr-0 cursor-pointer" size="16" icon="Trash2Icon" />
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </b-col>
                                                        </b-row>
                                                        <!-- ./Multiple Choice Question -->

                                                        <!-- Selection Question -->
                                                        <b-row v-if="type.tool === 'selectbox'">
                                                            <b-col cols="12">
                                                                <b-row>
                                                                    <b-col cols="6">
                                                                        <small>Choices</small> 
                                                                        <small class="text-danger" v-if="serverErrors && serverErrors.choices">({{ serverErrors.choices[0] }})</small>
                                                                    </b-col>
                                                                    <b-col cols="6" class="text-right">
                                                                        <b-button
                                                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                                            variant="flat-success"
                                                                            class="btn-icon"
                                                                            size="sm"
                                                                            title="Add row"
                                                                            @click="addRow()"
                                                                        >
                                                                            <feather-icon icon="PlusSquareIcon" />
                                                                        </b-button>
                                                                    </b-col>
                                                                </b-row>
                                                                <div
                                                                    style="border: 1px solid #eee;height:12vh;overflow-y:scroll;overflow-x: hidden;padding: 8px 8px;"
                                                                >
                                                                    <table class="table table-sm table-condensed" width="100%">
                                                                        <tbody>
                                                                            <tr v-for="(choice, index) in question.choices" :key="index">
                                                                                <td  width="5%">
                                                                                    {{ index + 1 }}
                                                                                </td>
                                                                                <td>
                                                                                    <b-form-input
                                                                                        size="sm"
                                                                                        name="description"
                                                                                        v-model="question.choices[index].description"
                                                                                        row="1"
                                                                                    />
                                                                                </td>
                                                                                <td width="5%">
                                                                                    <span
                                                                                        title="Remove this choice"
                                                                                        style="cursor:pointer"
                                                                                        @click="removeRow(index)"
                                                                                    >
                                                                                        <feather-icon class="ml-4 pr-0 cursor-pointer" size="16" icon="Trash2Icon" />
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </b-col>
                                                        </b-row>
                                                        <!-- ./Selection Question -->
                                                    </b-col>
                                                </b-row>

                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                    <!--/ general tab -->

                                    <!-- Mult-sections -->
                                    <b-tab
                                        v-for="(section, index) in selectedQuestionnaire.sections"
                                        :key="index"
                                        @click="changeSection(section)"
                                    >
                                        <!-- title -->
                                        <template #title>
                                            <feather-icon
                                            icon="DiscIcon"
                                            size="18"
                                            class="mr-50"
                                            />
                                            <span class="font-weight-bold">Section {{ section.word.substring(0, 1).toUpperCase() + section.word.substring(1).toLowerCase() }}</span>
                                        </template>

                                        <b-row>
                                            <b-col cols="12">
                                                <b-row>
                                                    <b-col cols="12" md="5">
                                                        <b-row>
                                                            <b-col cols="12">
                                                                <label for="textarea-default">Question</label>
                                                                <b-form-textarea
                                                                    id="textarea-default"
                                                                    placeholder="Type your question here"
                                                                    rows="2"
                                                                    v-model="question.description"
                                                                />
                                                                <small class="text-danger" v-if="serverErrors && serverErrors.description">{{ serverErrors.description[0] }}</small>
                                                            </b-col>
                                                        </b-row>

                                                        <b-row>
                                                            <b-col cols="8">
                                                                <label>Triggered by</label>
                                                                <b-form-select
                                                                    size="sm"
                                                                    v-model="question.question_id"
                                                                    @change="repopulateOptions"
                                                                >
                                                                    <b-form-select-option :value="null" selected>Select...</b-form-select-option>
                                                                    <b-form-select-option
                                                                        v-for="(question, index) in triggers"
                                                                        :key="index"
                                                                        :value="question.id"
                                                                        :v-if="question.options.length > 0"
                                                                    >
                                                                        {{ trigger.description }}
                                                                    </b-form-select-option>
                                                                </b-form-select>
                                                            </b-col>
                                                            <b-col cols="4">
                                                                <label>After Opting</label>
                                                                <b-form-select
                                                                    size="sm"
                                                                    v-model="question.option_id"
                                                                >
                                                                    <b-form-select-option :value="null" selected>Select...</b-form-select-option>
                                                                    <b-form-select-option
                                                                        v-for="option in options"
                                                                        :key="option.id"
                                                                        :value="option.id"
                                                                        selected
                                                                    >
                                                                        {{ option.value }}
                                                                    </b-form-select-option>
                                                                </b-form-select>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" md="7" v-if="type.tool !== 'textarea' && type.tool !== 'textbox' && type.tool !== 'numberbox'">
                                                        <!-- True/False Question -->
                                                        <b-row v-if="type.tool === 'binary'">
                                                            <b-col cols="12" v-if="serverErrors && serverErrors.labels">
                                                                <small class="text-danger">{{ serverErrors.labels[0] }}</small>
                                                            </b-col>
                                                            <b-col cols="12" md="3" class="pt-1">
                                                                <label for="trueLabel">True Label</label>
                                                                <b-form-input
                                                                    id="trueLabel"
                                                                    name="trueLabel"
                                                                    v-model="question.labels.trueLabel"
                                                                    size="sm"
                                                                    placeholder="e.g. Yes"
                                                                />
                                                            </b-col>
                                                            <b-col cols="12" md="3" class="pt-1">
                                                                <label for="falseLabel">False Label</label>
                                                                <b-form-input
                                                                    id="falseLabel"
                                                                    name="falseLabel"
                                                                    v-model="question.labels.falseLabel"
                                                                    size="sm"
                                                                    placeholder="e.g. No"
                                                                />
                                                            </b-col>
                                                        </b-row>
                                                        <!-- ./True/False Question -->

                                                        <!-- Rating Question -->
                                                        <b-row v-if="type.tool === 'rate'">
                                                            <b-col cols="4" md="2" class="pt-1">
                                                                <label for="minimum" :class="(serverErrors && serverErrors.minimum)? 'text-danger' : 'text-default'">Scale of</label>
                                                                <b-form-input
                                                                    id="minimum"
                                                                    name="minimum"
                                                                    v-model="question.minimum"
                                                                    size="sm"
                                                                    type="number"
                                                                />
                                                            </b-col>
                                                            <b-col cols="4" md="2" class="pt-1">
                                                                <label for="maximum" :class="(serverErrors && serverErrors.minimum)? 'text-danger' : 'text-default'">To</label>
                                                                <b-form-input
                                                                    id="maximum"
                                                                    name="maximum"
                                                                    v-model="question.maximum"
                                                                    size="sm"
                                                                    type="number"
                                                                />
                                                            </b-col>
                                                            <b-col cols="4" class="pt-1">
                                                                <b-form-group>
                                                                    <label for="display" :class="(serverErrors && serverErrors.display)? 'text-danger' : 'text-default'">Display System</label>
                                                                    <b-form-radio-group
                                                                        plain
                                                                        :options="[{ text: 'Stars', value: 'stars' }, { text: 'Number box', value: 'box' }]"
                                                                        id="display"
                                                                        name="display"
                                                                        v-model="question.display"
                                                                        stacked
                                                                    />
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <!-- ./Rating Question -->

                                                        <!-- Multiple Choice Question -->
                                                        <b-row v-if="type.tool === 'multiple'">
                                                            <b-col cols="12">
                                                                <b-row>
                                                                    <b-col cols="6">
                                                                        <small>Choices</small> 
                                                                        <small class="text-danger" v-if="serverErrors && (serverErrors.choices)">({{ serverErrors.choices[0] }})</small>
                                                                    </b-col>
                                                                    <b-col cols="6" class="text-right">
                                                                        <b-button
                                                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                                            variant="flat-success"
                                                                            class="btn-icon"
                                                                            size="sm"
                                                                            title="Add row"
                                                                            @click="addRow()"
                                                                        >
                                                                            <feather-icon icon="PlusSquareIcon" />
                                                                        </b-button>
                                                                    </b-col>
                                                                </b-row>
                                                                <div
                                                                    style="border: 1px solid #eee;height:12vh;overflow-y:scroll;overflow-x: hidden;padding: 8px 8px;"
                                                                >
                                                                    <table class="table table-sm table-condensed" width="100%">
                                                                        <tbody>
                                                                            <tr v-for="(choice, index) in question.choices" :key="index">
                                                                                <td  width="5%">
                                                                                    {{ index + 1 }}
                                                                                </td>
                                                                                <td>
                                                                                    <b-form-input
                                                                                        size="sm"
                                                                                        name="description"
                                                                                        v-model="question.choices[index].description"
                                                                                        row="1"
                                                                                    />
                                                                                </td>
                                                                                <td width="5%">
                                                                                    <span
                                                                                        title="Remove this choice"
                                                                                        style="cursor:pointer"
                                                                                        @click="removeRow(index)"
                                                                                    >
                                                                                        <feather-icon class="ml-4 pr-0 cursor-pointer" size="16" icon="Trash2Icon" />
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </b-col>
                                                        </b-row>
                                                        <!-- ./Multiple Choice Question -->

                                                        <!-- Selection Question -->
                                                        <b-row v-if="type.tool === 'selectbox'">
                                                            <b-col cols="12">
                                                                <b-row>
                                                                    <b-col cols="6"><small>Choices</small></b-col>
                                                                    <b-col cols="6" class="text-right">
                                                                        <b-button
                                                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                                            variant="flat-success"
                                                                            class="btn-icon"
                                                                            size="sm"
                                                                            title="Add row"
                                                                            @click="addRow()"
                                                                        >
                                                                            <feather-icon icon="PlusSquareIcon" />
                                                                        </b-button>
                                                                    </b-col>
                                                                </b-row>
                                                                <div
                                                                    style="border: 1px solid #eee;height:12vh;overflow-y:scroll;overflow-x: hidden;padding: 8px 8px;"
                                                                >
                                                                    <table class="table table-sm table-condensed" width="100%">
                                                                        <tbody>
                                                                            <tr v-for="(choice, index) in question.choices" :key="index">
                                                                                <td  width="5%">
                                                                                    {{ index + 1 }}
                                                                                </td>
                                                                                <td>
                                                                                    <b-form-input
                                                                                        size="sm"
                                                                                        name="description"
                                                                                        v-model="question.choices[index].description"
                                                                                        row="1"
                                                                                    />
                                                                                </td>
                                                                                <td width="5%">
                                                                                    <span
                                                                                        title="Remove this choice"
                                                                                        style="cursor:pointer"
                                                                                        @click="removeRow(index)"
                                                                                    >
                                                                                        <feather-icon class="ml-4 pr-0 cursor-pointer" size="16" icon="Trash2Icon" />
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </b-col>
                                                        </b-row>
                                                        <!-- ./Selection Question -->
                                                    </b-col>
                                                </b-row>

                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                    <!--/ change password tab -->
                                </b-tabs>
                                <!-- ./End Tabs -->
                                <b-row>
                                    <b-col cols="12" md="3" class="pt-1 text-left">
                                        <b-button
                                            size="sm"
                                            variant="primary"
                                            @click="submit"
                                        >
                                            <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                                            <span v-if="!saving">Save</span>
                                            <span v-if="saving">Saving...</span>
                                        </b-button>
                                    </b-col>
                                    <b-col cols="12" md="9">
                                        <b-row class="mb-1 mt-1">
                                            <b-col>
                                                For which project(s)? <small class="text-danger" v-if="serverErrors && serverErrors.projects">{{ serverErrors.projects[0] }}</small>
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="projects.length > 0">
                                            <b-col
                                                cols="12"
                                                :md="(12 / projects.filter((element) => (element.project_id === null || element.project_id === 0) && element.isProject === 1).length)"
                                                v-for="(project, index) in projects.filter((e) => (e.project_id === null || e.project_id === 0) && e.isProject === 1)"
                                            >
                                                <b-form-checkbox
                                                    :value="project.id"
                                                    :class="'custom-control-' + project.color"
                                                    v-model="question.projects"
                                                >
                                                    {{ project.abbreviation }}
                                                </b-form-checkbox>

                                                <!-- Sub project of main -->
                                                <b-row v-for="(firstSub, index) in project.projects" v-if="firstSub.project_id === project.id && question.projects.filter((e) => e === firstSub.project_id).length > 0">
                                                    <b-col cols="12" class="px-2">
                                                        <b-form-checkbox
                                                            :value="firstSub.id"
                                                            :class="'custom-control-' + project.color"
                                                            v-model="question.projects"
                                                        >
                                                            {{ firstSub.title }}
                                                        </b-form-checkbox>

                                                        <!-- Sub project of sub -->
                                                        <b-row v-for="(secondSub, index) in firstSub.projects" v-if="secondSub.project_id === firstSub.id && question.projects.filter((e) => e === secondSub.project_id).length > 0">
                                                            <b-col cols="12" class="px-2">
                                                                <b-form-checkbox
                                                                    :value="secondSub.id"
                                                                    :class="'custom-control-' + project.color"
                                                                    v-model="question.projects"
                                                                >
                                                                    {{ secondSub.title }}
                                                                </b-form-checkbox>

                                                                <b-row v-for="(thirdSub, index) in secondSub.projects" v-if="thirdSub.project_id === secondSub.id && question.projects.filter((e) => e === thirdSub.project_id).length > 0">
                                                                    <b-col cols="12" class="px-2">
                                                                        <b-form-checkbox
                                                                            :value="thirdSub.id"
                                                                            :class="'custom-control-' + project.color"
                                                                            v-model="question.projects"
                                                                        >
                                                                            {{ thirdSub.title }}
                                                                        </b-form-checkbox>

                                                                        <b-row v-for="(fourthSub, index) in thirdSub.projects" v-if="fourthSub.project_id === thirdSub.id && question.projects.filter((e) => e === fourthSub.project_id).length > 0">
                                                                            <b-col cols="12" class="px-2">
                                                                                <b-form-checkbox
                                                                                    :value="fourthSub.id"
                                                                                    :class="'custom-control-' + project.color"
                                                                                    v-model="question.projects"
                                                                                >
                                                                                    {{ fourthSub.title }}
                                                                                </b-form-checkbox>
                                                                            </b-col>
                                                                        </b-row>
                                                                    </b-col>
                                                                </b-row>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <!-- {{ question }} -->
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <!-- Answers -->
        <div v-if="selectedQuestionnaire">
            <b-card>
                <b-row v-for="(level, i) in levels" :key="i">
                    <b-col cols="12">
                        <b-row>
                            <b-col
                                v-for="(e, x) in level.switches"
                            >
                                <span
                                    :class="'text-light-' + e.color"
                                >
                                    <b-card-text class="mb-0">
                                        <span v-if="e.project.abbreviation === null">{{ e.project.title }}</span>
                                        <span v-if="e.project.abbreviation !== null">{{ e.project.abbreviation }}</span>
                                    </b-card-text>
                                    <b-form-checkbox
                                        :checked="e.selected"
                                        :class="'custom-control-' + e.color"
                                        name="check-button"
                                        switch
                                        v-model="e.selected"
                                        @change="refreshLevels(i, e)"
                                    >
                                        <span class="switch-icon-left">
                                            <feather-icon icon="CheckIcon" />
                                        </span>
                                        <span class="switch-icon-right">
                                            <feather-icon icon="BellOffIcon" />
                                        </span>
                                    </b-form-checkbox>
                                </span>
                            </b-col>
                        </b-row>
                        <hr />
                    </b-col>
                </b-row>
            
                <b-card-body v-if="myProject">
                    <div v-if="questions.length > 0" class="mb-1">
                        <!-- Table Top -->
                        <b-row>
                            <!-- Per Page -->
                            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                                <label>Show</label>
                                <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="[myProject.questions.length]" :clearable="false"
                                    class="per-page-selector d-inline-block mx-50 select-size-sm" />
                                <label>entries</label>
                            </b-col>
                            <!-- ./Per Page -->

                            <!-- Search -->
                            <b-col cols="12" md="6">
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-form-input
                                        v-model="searchQuery"
                                        class="d-inline-block mr-1"
                                        placeholder="Search..."
                                        size="sm"
                                        @keyup="search()"
                                    />
                                </div>
                            </b-col>
                            <!-- ./Search -->
                        </b-row>
                        <!-- ./Table Top -->
                    </div>

                    <!-- Table -->
                    <b-table
                        striped
                        hover
                        ref="records"
                        class="position-relative"
                        :items="myProject.questions"
                        responsive
                        :fields="['sno', 'description', 'response_tool', 'actions']"
                        primary-key="id"
                        show-empty
                        empty-text="No records found"
                        v-if="questions.length > 0"
                    >
                        <template #cell(sno)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template #cell(response_tool)="data">
                            {{ data.item.type.description }}
                        </template>

                        <!-- Column: Actions -->
                        <template #cell(actions)="data">
                            <b-dropdown
                                variant="link"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                            >

                                <template #button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                />
                                </template>
                
                                <b-dropdown-item @click="invokeUpdateForm(data.item)" :title='data.item.description'>
                                    <feather-icon icon="EditIcon" />
                                    <span class="align-middle ml-50">Edit</span>
                                </b-dropdown-item>
                
                                <b-dropdown-item @click="remove(data.item.id)">
                                    <feather-icon icon="TrashIcon" />
                                    <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                    <!-- </b-table> -->

                    <!-- Table Footer -->
                    <div class="mx-2 mb-2" v-if="questions.length > 0">
                        <b-row>
                            <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                            >
                                <span class="text-muted">Showing {{ 1 }} to {{ myProject.questions.length }} of {{ myProject.questions.length }} entries</span>
                            </b-col>
                            <!-- Pagination -->
                            <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                            >
                                <b-pagination
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                >
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </b-card-body>
            </b-card>
        </div>
    </div>
</template>

<script>
    import { FormWizard, TabContent } from 'vue-form-wizard'
    import vSelect from 'vue-select'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import {
        BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BFormRadio, BFormTextarea, BFormRadioGroup, BButton,
        BFormCheckbox, BAlert, BTable, BPagination,
        BTabs, BTab, BCard, BCardBody, BCardHeader, BCardTitle, BCardText, BDropdown, BDropdownItem, BSpinner,
    } from 'bootstrap-vue'
    // import ProjectsCollapsibleTree from '@core/components/cromis/ProjectsCollapsibleTree.vue'
    import QuestionsHierarchiesTree from '@core/components/cromis/QuestionsHierarchiesTree.vue'
    import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
    import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
    import Ripple from 'vue-ripple-directive'
    import { ref, onMounted, onUnmounted } from '@vue/composition-api'
    import store from '@/store'
    import { useToast } from 'vue-toastification/composition'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import questionsStoreModule from '@/views/cromis/visitings/questions/questionsStoreModule'
    import moment from 'moment'

    export default {
        props: {},
        components: {
            FormWizard, TabContent, BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BFormRadio, BFormTextarea, BFormRadioGroup, BButton,
            BFormCheckbox, BAlert, BDropdown, BDropdownItem,
            BTabs, BTab, BCard, BCardBody, BCardText, BTable, BPagination,
            vSelect,
            BCard,
            BCardBody,
            BCardHeader,
            BCardTitle,
            BSpinner,
            AppTimeline,
            AppTimelineItem,
            QuestionsHierarchiesTree,
            ToastificationContent,
        },

        directives: {
            Ripple,
        },

        setup(props, context) {
            const saving = ref(false)
            const serverErrors = ref(null)
            const seasons = ref([])
            const season_id = ref(null)
            const questionsTypes = ref([])
            const type = ref(null)
            const questionnaires = ref([])
            const projects = ref([])
            // const questions = ref([])
            const selectedQuestionnaire = ref(null)
            const options = ref([]);
            const choices = ref([
                { description: null },
                { description: null },
            ])

            const question = ref({
                id: null,
                questionnaire_id: null,
                sections: 0,
                section_id: null,
                description: null,
                type_id: null,
                tool: null,
                labels: { trueLabel: null, falseLabel: null },
                minimum: null,
                maximum: null,
                display: null,
                choices: [{ description: null }, { description: null }],
                projects: [],
                question_id: null,
                option_id: null,
            })

            const triggers = ref([])

            const switches = ref([])
            const levels = ref([])
            const myProject = ref(null)
            const questions = ref([])
            const searchQuery = ref('')
            const perPage = ref(0)
            const found = ref(false)


            const formSubmitted = () => {
                context.root.$toast({
                    component: ToastificationContent,
                    props: {
                    title: 'Form Submitted',
                    icon: 'EditIcon',
                    variant: 'success',
                    },
                })
            }

            const resetForm = () => {
                serverErrors.value = null
                question.value = {
                    id: null,
                    questionnaire_id: null,
                    sections: 0,
                    section_id: null,
                    description: null,
                    type_id: null,
                    tool: null,
                    labels: { trueLabel: null, falseLabel: null },
                    minimum: null,
                    maximum: null,
                    display: null,
                    choices: [{ description: null }, { description: null }],
                    projects: [],
                    question_id: null,
                    option_id: null,
                }
            }

            const invokeUpdateForm = (item) => {
                question.value = {
                    id: item.id,
                    questionnaire_id: item.questionnaire_id,
                    sections: 0,
                    section_id: item.section_id,
                    description: item.description,
                    type_id: item.type.id,
                    tool: item.type.tool,
                    labels: { trueLabel: null, falseLabel: null },
                    minimum: null,
                    maximum: null,
                    display: null,
                    choices: item.options.filter((element) => element.id > 0).map((element) => ({ description: element.value })),
                    projects: extractProjectsIds(projects.value),
                    question_id: item.question_id !== null? Number(item.question_id) : item.question_id,
                    option_id: item.option_id !== null? Number(item.option_id) : item.option_id,
                }

                type.value = item.type
                options.value = extractTriggerOptions(item, projects.value.filter((element) => !!element.isProject))
            }

            const changeSection = (section) => {
                question.value.section_id = section.id
            }

            const search = () => {
                if(searchQuery.value === ''){
                    myProject.value.questions = questions.value
                }
                else{
                    myProject.value.questions = questions.value.filter((element) => element.description.toLowerCase().includes(searchQuery.value.toLowerCase()))
                }
                // myProject.value.questions = myProject.value.questions.filter((element) => element.description.toLowerCase().includes(searchQuery.value.toLowerCase()))
            }

            const extractProjectsIds = (projects) => {
                let ids = []
                found.value = false

                for(let i = 0; i < projects.length; i++){
                    if(!found.value){
                        ids.splice(0)
                    }
                    else{
                        break;
                    }

                    ids.push(projects[i].id)
                    if(projects[i].id === myProject.value.under.id){
                        found.value = true
                        break;
                    }
                    else{
                        if(projects[i].projects.length > 0){
                            ids = [...ids, ...extractProjectsIds(projects[i].projects)]
                        }
                    }
                }

                return ids
            }

            const extractTriggeringQuestions = (projectsList) => {
                for(let i = 0; i < projectsList.length; i++){
                    triggers.value = [...triggers.value, ...projectsList[i].questions]
                    extractTriggeringQuestions(projectsList[i].projects)
                }
            }

            const extractTriggerOptions = (question, projectsList) => {
                let options = []
                if(question.question_id !== null && Number(question.option_id) !== 0){
                    for(let i = 0; i < projectsList.length; i++){
                        for(let x = 0; x < projectsList[i].questions.length; x++){
                            if(projectsList[i].questions[x].id === Number(question.question_id)){
                                options = projectsList[i].questions[x].options
                                break;
                            }
                        }

                        if(options.length <= 0){
                            if(projectsList[i].projects.length > 0){
                                options = extractTriggerOptions(question, projectsList[i].projects)
                            }
                        }
                        else{
                            break;
                        }
                    }
                }

                return options
            }

            const changeCycle = async () => {
                await store.dispatch('cromis-question/projects', { questionnaire_id: selectedQuestionnaire.value.id, main: 1, sortBy: 'title', sortDesc: false })
                            .then(response => {
                                projects.value = response.data.projects
                                triggers.value.splice(0)
                                extractTriggeringQuestions(projects.value)

                                projects.value.map(e => {
                                    if(!!e.isProject){
                                        switches.value.push({project: e, questions: e.questions, color: e.color, selected: false })
                                    }
                                })

                                levels.value.push({ switches: switches.value })
                            })
                            .catch(error => {
                                console.log('errors qnnaires,', error)
                            })
            }

            const refreshLevels = (index, e) => {
                let newSwitches = []

                for(let x = levels.value.length - 1; x > index; x--){
                    levels.value.splice(x, 1)
                }

                if(e.selected){
                    myProject.value = {under: {id: e.project.id, title: e.project.title, abbreviation: e.project.abbreviation, project_id: e.project.project_id}, questions: e.project.questions, color: e.color}
                    perPage.value = myProject.value.questions.length
                    questions.value = myProject.value.questions

                    levels.value[index].switches.map((element) => {
                        if(element.project.id !== e.project.id){
                            element.selected = false
                        }
                    })

                    e.project.projects.map((element) => {
                        newSwitches.push({project: element, questions: element.questions, color: e.color, selected: false })
                    })

                    if(newSwitches.length > 0){
                        levels.value.push({ switches: newSwitches })
                    }
                }
                else{
                    myProject.value = null
                    perPage.value = 0
                    questions.value = []
                }
            }

            const changeTool = () => {
                question.value.tool = type.value.tool
                question.value.type_id = type.value.id
            }

            const repopulateOptions = () => {
                if(question.value.question_id){
                    options.value = []
                    question.value.option_id = null
                    triggers.value.map((element, index) => {
                        if(element.id === question.value.question_id){
                            options.value = element.options
                        }
                    })
                }
                else{
                    options.value = []
                    question.value.option_id = null
                }
            }

            const reachedEndDate = (endDate) => {
                if(moment(new Date()).format('YYYY-MM-DD') > moment(endDate).format('YYYY-MM-DD'))
                    return true

                return false
            }

            //Methods for dynamic table rows
            const addRow = () => {
                question.value.choices.push({ description: null })
            }

            const removeRow = (index) => {
                if(question.value.choices.length > 2){
                    question.value.choices.splice(index, 1)
                }
            }

            const CROMIS_STORE_MODULE_NAME = 'cromis-question'
            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, questionsStoreModule)
            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                await store.dispatch('cromis-question/types')
                            .then(response => {
                                questionsTypes.value = response.data.types
                                questionsTypes.value.map((initialType, index) => {
                                    if(index === 0){
                                        type.value = initialType
                                        question.value.type_id = initialType.id
                                        question.value.tool = initialType.tool
                                        changeTool()
                                    }
                                })
                            })
                            .catch(error => {
                                console.log('errors types,', error)
                            })
                            
                await store.dispatch('cromis-question/seasons', { sortBy: 'code', sortDesc: true })
                            .then(response => {
                                seasons.value = response.data.seasons

                                seasons.value.map((season) => {
                                    if(season.isRunning){
                                        // questionnaire.value.season_id = season.id
                                        season_id.value = season.id
                                    }
                                })

                                fetchQuestionnaires()
                            })
                            .catch(error => {
                                console.log('errors seasons,', error)
                            })

                // await fetchQuestionnaires()
            })

            const fetchQuestionnaires = async () => {
                await store.dispatch('cromis-question/season_questionnaires', { season_id: season_id.value, sortBy: 'cycle', status: 1, sortDesc: false })
                            .then(response => {
                                questionnaires.value = response.data.questionnaires

                                questionnaires.value.map((questionnaire, index) => {
                                    
                                    if(index === 0){
                                        selectedQuestionnaire.value = questionnaire
                                        question.value.questionnaire_id = questionnaire.id
                                        question.value.sections = questionnaire.sections.length
                                        question.value.section_id = questionnaire.sections.length > 0 ? questionnaire.sections[0].id : null
                                    }
                                })

                                if(selectedQuestionnaire.value !== null){
                                    changeCycle()
                                }
                            })
                            .catch(error => {
                                console.log('Hey')
                                console.log('errors qnnaires,', error)
                            })
            }

            const submit = (bvModalEvt) => {
                bvModalEvt.preventDefault()
                serverErrors.value = null

                // Handle form submit
                if (question.value.id === null || question.value.id === 0)
                    handleCreate()
                else
                    handleUpdate(question.value)
            }

            const handleCreate = async () => {
                saving.value = true

                await store.dispatch('cromis-question/create', question.value)
                    .then(async response => {
                        saving.value = false
                        let selectedId = selectedQuestionnaire.value.id
                        resetForm()

                        context.root.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Congratulations!`,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                                text: `You have successfully added a new question!`,
                            },
                        })

                        populateQuestionnaires(selectedId);

                        // await fetchQuestionnaires()
                        
                    })
                    .catch(error => {
                        console.log('errors create,', error)
                        saving.value = false
                        resetForm()
                        if(error.response && error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Server Error!`,
                                    icon: 'error',
                                    variant: 'danger',
                                    text: `Something went wrong. See tech support!!!`,
                                },
                            })
                        }
                    })
            }

            const populateQuestionnaires = async (selectId) => {
                await store.dispatch('cromis-question/questionnaires', { season_id: season_id.value, sortBy: 'cycle', sortDesc: true })
                            .then(response => {
                                questionnaires.value = response.data.questionnaires
                                questionnaires.value.forEach((questionnaire, index) => {
                                    if(questionnaire.id === selectId){
                                        selectedQuestionnaire.value = questionnaire
                                    }
                                })
                            })
                            .catch(error => {
                                console.log('errors qnnaires,', error)
                            })
            }

            const handleUpdate = async (item) => {
                saving.value = true

                await store.dispatch('cromis-question/update', { id: item.id, data: item })
                    .then(async response => {
                        // refetch()
                        saving.value = false

                        context.root.$swal({
                            icon: 'success',
                            text: `Changes to visit cycle and questionnaire has been made successfully!`,
                            showConfirmButton: true,
                            timer: 3000,
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                            buttonsStyling: false,
                        })

                        // await fetchQuestionnaires()


                        resetForm()
                    })
                    .catch(error => {
                        saving.value = false
                        console.log(error)
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const remove = async (id) => {
                await store.dispatch('cromis-question/remove', id)
                            .then(async response => {
                                console.log('response ok')
                                context.root.$swal({
                                    icon: 'success',
                                    text: 'Question delete successfully!',
                                    showConfirmButton: true,
                                    timer: 3000,
                                    customClass: {
                                        confirmButton: 'btn btn-outline-success',
                                    },
                                    buttonsStyling: false,
                                })

                                // await fetchQuestionnaires()
                            })
                            .catch(error => {
                                console.log('errors remove,', error)
                                context.root.$swal({
                                    icon: 'error',
                                    title: 'Server Error',
                                    text: 'Something went wrong. See tech support',
                                    showConfirmButton: false,
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                    buttonsStyling: false,
                                })
                            })
            }


            return {
                saving,
                serverErrors,
                seasons,
                season_id,
                questionnaires,
                projects,
                selectedQuestionnaire,
                questionsTypes,
                type,
                choices,
                formSubmitted,
                invokeUpdateForm,
                reachedEndDate,
                addRow,
                removeRow,
                question,
                // questions,
                options,
                repopulateOptions,
                triggers,
                changeSection,
                changeCycle,
                changeTool,
                submit,
                remove,
                fetchQuestionnaires,

                switches,
                levels,
                refreshLevels,
                myProject,
                questions,
                perPage,
                search,
                searchQuery,
            }
        }
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-wizard.scss';
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
